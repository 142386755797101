import React from 'react'
import PropTypes from 'prop-types'
import { Box, Chip, Typography } from '@mui/material'
import Image from '../image'
import { createProvenTheme } from '../../../../styles/theme-proven'

const theme = createProvenTheme()

function BeforeAfterSlide({ name, imgBefore, imgAfter, quote, tags }) {
  return (
    <Box
      sx={{
        pt: 3,
        '.slick-center &': {
          pt: 0,
          boxShadow: 3,
          borderRadius: 2
        }
      }}
    >
      <Image before={imgBefore} after={imgAfter} withoutLazy />
      <Box
        sx={{
          p: [2, 2, 3],
          display: 'none',
          '.slick-center &': {
            display: 'block'
          }
        }}
      >
        <Typography variant="title2" color="gray.elysian" mb={1} component="div">
          {name}
        </Typography>
        <Typography variant="footnote" color="gray.darkGray" mb={4} component="div">
          {quote}
        </Typography>
        <Typography variant="footnote" color="gray.darkGray" mb={1.5} component="div">
          Effectively reduced:
        </Typography>
        {tags?.length > 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, flexWrap: 'wrap' }}>
            {tags.map((tag, i) => (
              <Chip
                key={`${tag}-${i}`}
                color="primary"
                label={tag}
                sx={{ color: `${theme.palette.gray.elysian} !important` }}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}

BeforeAfterSlide.propTypes = {
  name: PropTypes.string.isRequired,
  imgBefore: PropTypes.string.isRequired,
  imgAfter: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  tags: PropTypes.array
}

export default BeforeAfterSlide
