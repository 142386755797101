import React from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import propTypes from 'prop-types'
import { onDesktop } from '../../../styles/theme-proven/responsive'
import { BUILDER_CDN } from '../../../constants/endpoints'
import PricingWithProofing from '../pricing/pricing-with-proofing'
import useAuth from 'hooks/use-auth'
import ProvenButtonAddToCart from '../proven-button-add-to-cart'
import {
  CLEANSER_PRODUCT,
  DAY_EYE_CREAM_PRODUCT,
  DAY_MOISTURIZER_PRODUCT,
  EYE_CREAM_PRODUCT,
  NIGHT_CREAM_PRODUCT,
  NIGHT_EYE_CREAM_PRODUCT,
  SERUM_PRODUCT,
  SYSTEM_PRODUCT
} from '../../../constants/products'
import { Image } from 'components/builder/blocks/shared/Image'

const CTA_TEXT = 'Get my system'
const DATA_CY_PREFIX = 'call-to-action'

function CallToAction(props) {
  const { isAuthenticated } = useAuth()

  const isDesktop = useMediaQuery(onDesktop)
  console.log('CTA props', props)

  return (
    <Box
      data-cy={`${DATA_CY_PREFIX}-section`}
      sx={{
        position: 'relative',
        mx: { xs: -2, md: -8, lg: '-150px', xxl: '-10%' }
      }}
    >
      <Image
        lazy
        backgroundSize="cover"
        backgroundPosition="top"
        image={
          isDesktop
            ? `${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69%2F175c5bc43d6a45b492ed49db4bc94b38`
            : `${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69%2Fae0b9ec3c59d4be5bef372f7b6d6a2e9`
        }
        sx={{
          position: 'absolute',
          left: 0,
          top: { sm: -32, md: 0 },
          height: '100%',
          width: '100%'
        }}
      />
      <Grid
        container
        sx={{
          display: 'flex',
          alignContent: 'flex-end',
          textAlign: 'center',
          height: 596,
          px: { xs: 2, md: 8, lg: '150px', xxl: '10%' },
          pb: 8,
          mx: 0,
          [onDesktop]: {
            alignContent: 'center',
            textAlign: 'left',
            height: { md: 510, lg: 775 },
            px: { md: 8 },
            pb: 0
          }
        }}
      >
        <Grid item xs={12} md={8} zIndex={1}>
          <Typography
            variant="h2"
            sx={{
              mb: !props.subTitle ? { xs: 3, md: 5 } : undefined,
              mx: 'auto',
              maxWidth: '660px',
              [onDesktop]: {
                fontSize: { md: 42, lg: 60 },
                maxWidth: { md: 660, lg: 'unset' },
                mb: { md: 5 },
                position: 'relative'
              }
            }}
          >
            {props.title}
          </Typography>
          {props.subTitle && (
            <Box
              mt={5}
              mb={3}
              display="flex"
              minWidth="max-content"
              sx={{ justifyContent: { xs: 'center', md: 'left' }, minWidth: 'max-content' }}
            >
              <Typography
                variant="title1"
                color="gray.darkGray"
                mr={0.5}
                display="block"
                sx={{ whiteSpace: 'nowrap !important' }}
              >
                {props.subTitle}
              </Typography>
              <PricingWithProofing variant="cart" />
            </Box>
          )}
          <Box
            data-testid="call-to-action-button-container-testId"
            sx={{
              '& button': {
                mx: { xs: 'auto', lg: 0 },
                width: { sm: 343, lg: 'fit-content' }
              },
              '&>div>div:last-child': {
                // width: { md: '100%' },
                alignItems: { md: 'center', lg: 'flex-start' },
                whiteSpace: 'nowrap'
              },
              [onDesktop]: {
                display: { md: 'block', lg: 'flex' }
              }
            }}
          >
            <ProvenButtonAddToCart
              size="medium"
              variant="contained"
              color="secondary"
              textToShowForAuthenticated={props.buttonText}
              textToShowForUnauthenticated={props.buttonText}
              addPriceWhenIsAuthenticated={true}
              addSavingCancellingAndShippingLegend
              productId={props.product}
              data-cy={isAuthenticated ? `${DATA_CY_PREFIX}-cta-button` : 'start-skin-quiz-button'}
              sx={{
                alignSelf: { xs: 'center', md: 'center' }
              }}
              showPrice={props.showPrice}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

CallToAction.propTypes = {
  title: propTypes.string,
  subTitle: propTypes.string,
  buttonText: propTypes.string,
  product: propTypes.oneOf([
    SYSTEM_PRODUCT,
    DAY_MOISTURIZER_PRODUCT,
    NIGHT_CREAM_PRODUCT,
    CLEANSER_PRODUCT,
    EYE_CREAM_PRODUCT,
    NIGHT_EYE_CREAM_PRODUCT,
    DAY_EYE_CREAM_PRODUCT,
    SERUM_PRODUCT
  ]).isRequired,
  showPrice: propTypes.bool
}

CallToAction.defaultProps = {
  title: 'Start Your Personal Skincare Journey Today',
  buttonText: CTA_TEXT,
  product: SYSTEM_PRODUCT,
  showPrice: true
}

export default CallToAction
